<template>
  <div v-if="show" class="vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3 mb-base">
    <div class="text-success">
      <!-- 1% -->
      <feather-icon icon></feather-icon>
    </div>
    <span>
      <b>{{ Math.round(ongoingTarget).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}</b>
    </span>
    <div>SPECIAL ONGOING TARGET</div>
  </div>
</template>
<script>
export default {
  components: {},
  created() {
    this.getData();
  },
  props: {
    type: {
      type: String,
    },
    year: {
      type: Number,
    },
    month: {
      type: Number,
    },
    week: {
      type: Number,
    },
    salesChannelId: {
      type: Number,
    },
    customerCategoryId: {
      type: Number,
    },
    status: {
      type: String,
    },
  },
  data() {
    return {
      total_target: {
        nominalValue: 0,
      },
      total_completion: {
        nominalValue: 0,
      },
      ongoingTarget: 0,
    };
  },
  methods: {
    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/dashboard/total-target", {
          params: {
            type: this.type,
            year: this.year,
            month: this.month,
            week: this.week,
            status: this.status,
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.total_target.nominalValue = resp.data.total_target;

            this.$vs.loading.close();
            this.getOngoingTarget();
          }
        });

      this.$http
        .get("/api/v1/dashboard/total-completion", {
          params: {
            type: this.type,
            year: this.year,
            month: this.month,
            week: this.week,
            sales_channel_id: this.salesChannelId,
            customer_category_id: this.customerCategoryId,
            status: this.status,
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            if (resp.data[0] != null) {
              this.total_completion.nominalValue = resp.data[0].goal_completion;
            }

            this.$vs.loading.close();
            this.getOngoingTarget();
          }
        });
    },
    getOngoingTarget() {
      var target = this.total_target.nominalValue;
      var completion = this.total_completion.nominalValue;

      if (target > completion) {
        this.ongoingTarget = target - completion;
      } else {
        this.ongoingTarget = 0;
      }
    },
  },
  mounted() {
    this.getOngoingTarget();
  },
  computed: {
    show() {
      let show = true;
      if (this.$userLogin.roles) {
        this.$userLogin.roles.filter(function (item) {
          switch (item.Name) {
            case "fulfillment":
              show = false;
              break;
          }
        }, show);
      }
      return show;
    },
  },
  watch: {
    week() {
      this.getData();
    },
    month() {
      this.getData();
    },
    year() {
      this.getData();
    },
    salesChannelId() {
      this.getData();
    },
    customerCategoryId() {
      this.getData();
    },
  },
};
</script>