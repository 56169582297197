<template>
  <vx-card title="Data Goal Completion">
    <div v-for="(item, index) in data" :key="item.name" :class="{'mt-4': index}">
      <div class="flex justify-between">
        <div class="flex flex-col">
          <span class="mb-1">{{ item.name }}</span>
          <h4>{{ item.ratio }}%</h4>
        </div>
        <div class="flex flex-col text-right">
          <span class="flex -mr-1">
            <span class="mr-1">{{ item.comparedResult.toString().replace("-", "") }}%</span>
            <feather-icon
              :icon=" item.comparedResult !=0 ?item.comparedResult < 0 ? 'ArrowDownIcon' : 'ArrowUpIcon':'MinusIcon'"
              :svgClasses="[item.comparedResult < 0 ? 'text-danger' : 'text-success'  ,'stroke-current h-4 w-4 mb-1 mr-1']"
            ></feather-icon>
          </span>
          <span class="text-grey">{{ item.complete }} / {{item.total}}</span>
        </div>
      </div>
      <vs-progress :percent="item.ratio"></vs-progress>
    </div>
  </vx-card>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      require: true
    },
    year: {
      type: Number,
      require: true
    },
    month: {
      type: Number
    },
    week: {
      type: Number
    },
    salesChannelId: {
      type: Number
    },
    customerCategoryId: {
      type: Number
    },
    territoryId: {
      type: Number
    },
    territoryAreaId: {
      type: Number
    },
    salesPersonalId: {
      type: Number
    },
    managerPersonalId: {
      type: Number
    },
    directorPersonalId: {
      type: Number
    }
  },
  data() {
    return {
      baseUrl: "/api/v1/dashboard/goal-completion",
      data: []
    };
  },
  methods: {
    getData() {
      this.$http
        .get(this.baseUrl, {
          params: {
            type: this.type,
            year: this.year,
            month: this.month,
            week: this.week,
            sales_channel_id: this.salesChannelId,
            customer_category_id: this.customerCategoryId,
            sales_personal_id: this.salesPersonalId,
            manager_personal_id: this.managerPersonalId,
            director_personal_id: this.directorPersonalId,
            territory_id: this.territoryId,
            territory_area_id: this.territoryAreaId
          }
        })
        .then(resp => {
          if (resp.status == "success") {
            this.data = resp.data;
          } else {
            this.$vs.loading.close();
          }
        });
    }
  },
  mounted() {
    this.getData();
  },
  watch: {
    year() {
      this.getData();
    },
    month() {
      this.getData();
    },
    week() {
      this.getData();
    },
    salesChannelId() {
      this.getData();
    },
    customerCategoryId() {
      this.getData();
    },
    salesPersonalId() {
      this.getData();
    },
    managerPersonalId() {
      this.getData();
    },
    directorPersonalId() {
      this.getData();
    },
    territoryId() {
      this.getData();
    },
    territoryAreaId() {
      this.getData();
    }
  }
};
</script>