<template>
  <div>
    <box-row></box-row>
    <grafik-weekly></grafik-weekly>
    <grafik-monthly></grafik-monthly>
    <grafik-yearly></grafik-yearly>
  </div>
</template>

<script>
import GoalCompletion from "./GoalCompletion";
import GrafikWeekly from "./GrafikWeekly";
import GrafikMonthly from "./GrafikMonthly";
import GrafikYearly from "./GrafikYearly";
import BoxRow from "./BoxRow";

export default {
  components: {
    GoalCompletion,
    GrafikWeekly,
    GrafikMonthly,
    GrafikYearly,
    BoxRow
  }
};
</script>


