<template>
  <div v-if="show" class="vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3 mb-base">
    <div :class="total_target.status == 'down' ? 'text-danger' : 'text-success'">
      <feather-icon :icon="total_target.status == 'down' ? 'ArrowDownIcon' : 'ArrowUpIcon'" :svgClasses="['stroke-current h-4 w-4 mb-1 mr-1']"></feather-icon>
      {{ total_target.comparedValue }}%
    </div>
    <span>
      <b>{{ Math.round(total_target.nominalValue).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}</b>
    </span>
    <div>TOTAL TARGET</div>
  </div>
</template>
<script>
export default {
  props: {
    type: {
      type: String,
      require: true
    },
    year: {
      type: Number,
      require: true
    },
    month: {
      type: Number
    },
    week: {
      type: Number
    }
  },
  data() {
    return {
      total_target: {
        nominalValue: 0,
        comparedValue: 0,
        status: "",
      },
    }
  },
  methods: {
    getData() {
      this.$vs.loading();
      this.$http
      .get("/api/v1/dashboard/total-target", {
        params: {
          type: this.type,
          year: this.year,
          month: this.month,
          week: this.week,
        }
      })
      .then(resp => {
        if (resp.status == "success") {
          this.total_target.nominalValue = resp.data.total_target;
          this.total_target.comparedValue = resp.data.compared_value;
          this.total_target.status = resp.data.status;

          this.$vs.loading.close();
        }
      });
    },
  },
  mounted() {
    this.getData();
  },
  computed: {
    show() {
      let show = true;
      if (this.$userLogin.roles) {
        this.$userLogin.roles.filter(function(item) {
          switch (item.Name) {
            case "fulfillment":
              show = false;
              break;
          }
        }, show);
      }
      return show;
    }
  },
  watch: {
    week() {
      this.getData();
    },
    month() {
      this.getData();
    },
    year() {
      this.getData();
    }
  }
};
</script>