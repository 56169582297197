<template>
  <div class="vx-row">
    <div class="vx-col w-full md:w-3/4 lg:w-3/4 xl:w-3/4 mb-base">
      <vx-card :title="title">
        <!-- SLOT = ACTION -->
        <template slot="actions">
          <div class="flex items-center">
            <div class="vx-col w-full md:w-3/6 lg:w-3/6 xl:w-3/6 flex mr-5">
              <!-- <feather-icon :icon="'CalendarIcon'" :svgClasses="'stroke-current h-4 w-4 mb-1 mr-2'"></feather-icon> -->
              <vuejs-datepicker
                width="100%"
                v-model="selectedYear"
                format="yyyy"
                minimum-view="year"
                name="datepicker"
                input-class="input-class"
              ></vuejs-datepicker>
            </div>
          </div>
        </template>
        <div slot="no-body" class="p-6 pt-0 pb-0">
          <vs-row vs-type="flex" vs-justify="flex-end" class="mb-5">
            <div class="vx-col md:w-1/3 lg:w-1/3 xl:w-1/3 mr-5">
              <label class="vs-input--label">Week</label>
              <v-select
                v-model="weekList.selected"
                :options="weekList.options"
              />
            </div>
            <customer-category
              @customerCategoryId="setCustomerCategoryId"
            ></customer-category>
            <sales-channel @salesChannelId="setSalesChannelId"></sales-channel>
            <sales
              @salesPersonalId="setSalesPersonalId"
              :territoryId="territoryId"
            ></sales>
            <territory
              @managerPersonalId="setManagerPersonalId"
              @territoryId="setTerritoryId"
              :territoryAreaId="territoryAreaId"
            ></territory>
            <sales-area
              @directorPersonalId="setDirectorPersonalId"
              @territoryAreaId="setTerritoryAreaId"
            ></sales-area>
          </vs-row>
          <div class="flex">
            <total-value-this
              :type="type"
              :year="year"
              :week="week"
              :salesChannelId="salesChannelId"
              :customerCategoryId="customerCategoryId"
              :territoryId="territoryId"
              :territoryAreaId="territoryAreaId"
              :salesPersonalId="salesPersonalId"
              :managerPersonalId="managerPersonalId"
              :directorPersonalId="directorPersonalId"
              :label="'Week'"
            ></total-value-this>
            <total-value-last
              :type="type"
              :year="year"
              :week="week"
              :salesChannelId="salesChannelId"
              :customerCategoryId="customerCategoryId"
              :territoryId="territoryId"
              :territoryAreaId="territoryAreaId"
              :salesPersonalId="salesPersonalId"
              :managerPersonalId="managerPersonalId"
              :directorPersonalId="directorPersonalId"
              :label="'Week'"
            ></total-value-last>
            <total-sales-order
              :type="type"
              :year="year"
              :week="week"
              :salesChannelId="salesChannelId"
              :customerCategoryId="customerCategoryId"
              :territoryId="territoryId"
              :territoryAreaId="territoryAreaId"
              :salesPersonalId="salesPersonalId"
              :managerPersonalId="managerPersonalId"
              :directorPersonalId="directorPersonalId"
            ></total-sales-order>
          </div>
          <grafik
            :type="type"
            :year="year"
            :week="week"
            :salesChannelId="salesChannelId"
            :customerCategoryId="customerCategoryId"
            :territoryId="territoryId"
            :territoryAreaId="territoryAreaId"
            :salesPersonalId="salesPersonalId"
            :managerPersonalId="managerPersonalId"
            :directorPersonalId="directorPersonalId"
          ></grafik>
        </div>
        <div class="flex text-center">
          <total-target :type="type" :year="year" :week="week"></total-target>
          <total-completion
            :type="type"
            :year="year"
            :week="week"
            :salesChannelId="salesChannelId"
            :customerCategoryId="customerCategoryId"
          ></total-completion>
          <total-target-ongoing
            :type="type"
            :year="year"
            :week="week"
            :salesChannelId="salesChannelId"
            :customerCategoryId="customerCategoryId"
          ></total-target-ongoing>
        </div>
        <div class="flex text-center">
          <!-- <total-target-special :type="type" :year="year" :week="week" :status="'special'"></total-target-special> -->
          <!-- <total-completion-special :type="type" :year="year" :week="week" :salesChannelId="salesChannelId" :customerCategoryId="customerCategoryId"></total-completion-special> -->
          <!-- <total-target-ongoing-special :status="'special'" :type="type" :year="year" :week="week" :salesChannelId="salesChannelId" :customerCategoryId="customerCategoryId"></total-target-ongoing-special> -->
        </div>
      </vx-card>
    </div>
    <div class="vx-col w-full md:w-1/4 lg:w-1/4 xl:w-1/4 mb-base">
      <goal-completion
        :type="type"
        :year="year"
        :week="week"
        :salesChannelId="salesChannelId"
        :customerCategoryId="customerCategoryId"
        :territoryId="territoryId"
        :territoryAreaId="territoryAreaId"
        :salesPersonalId="salesPersonalId"
        :managerPersonalId="managerPersonalId"
        :directorPersonalId="directorPersonalId"
      ></goal-completion>
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import GoalCompletion from "./GoalCompletion";
import Grafik from "./dashboard-component/Grafik";
import TotalCompletion from "./dashboard-component/TotalCompletion";
import TotalTarget from "./dashboard-component/TotalTarget";
import TotalTargetOngoing from "./dashboard-component/TotalTargetOngoing";
import TotalSalesOrder from "./dashboard-component/TotalSalesOrder";
import TotalValueThis from "./dashboard-component/TotalValueThis";
import TotalValueLast from "./dashboard-component/TotalValueLast";
import SalesChannel from "./dashboard-component/SalesChannel";
import CustomerCategory from "./dashboard-component/CustomerCategory";
import Sales from "./dashboard-component/Sales";
import Territory from "./dashboard-component/Territory";
import SalesArea from "./dashboard-component/SalesArea";
import vSelect from "vue-select";

import TotalCompletionSpecial from "./dashboard-component/TotalCompletionSpecial";
import TotalTargetSpecial from "./dashboard-component/TotalTargetSpecial";
import TotalTargetOngoingSpecial from "./dashboard-component/TotalTargetOngoingSpecial";

export default {
  components: {
    "vuejs-datepicker": Datepicker,
    "v-select": vSelect,
    GoalCompletion,
    Grafik,
    TotalCompletion,
    TotalTarget,
    TotalTargetOngoing,
    TotalSalesOrder,
    TotalValueThis,
    TotalValueLast,
    SalesChannel,
    CustomerCategory,
    TotalCompletionSpecial,
    TotalTargetSpecial,
    TotalTargetOngoingSpecial,
    Sales,
    Territory,
    SalesArea,
  },
  data() {
    return {
      title: "Weekly Order Report " + this.$userLogin.roles[0].DisplayName,
      total_target: {
        week: 0,
        compared_week: 0,
        status_week: 0,
      },
      selectedYear: Date.now(),
      type: "weekly",
      year: new Date().getFullYear(),
      week: 0,
      weekList: {
        selected: null,
        options: [],
      },
      salesChannelId: null,
      customerCategoryId: null,
      salesPersonalId: null,
      managerPersonalId: null,
      directorPersonalId: null,
      territoryId: null,
      territoryAreaId: null,
    };
  },
  methods: {
    getDataWeeklist() {
      if (this.year) {
        this.$http
          .get("/api/v1/master/target-management/week/" + this.year)
          .then((resp) => {
            if (resp.status == "success") {
              var options = [];
              this.weekList.selected = null;
              var selected = null;
              resp.data.forEach(
                function (item) {
                  options.push({
                    label:
                      item.week +
                      " (" +
                      item.start_date +
                      " - " +
                      item.end_date +
                      ")",
                    value: item.week,
                  });
                  var dateNow = Date.now();
                  // if (
                  //   dateNow >= new Date(item.start_date + " 00:00:00") &&
                  //   dateNow <= new Date(item.end_date + " 23:59:59")
                  // ) {
                  if (
                    dateNow >= Date.parse(item.start_date + "T00:00:00Z") &&
                    dateNow <= Date.parse(item.end_date + "T23:59:59Z")
                  ) {
                    selected = {
                      label:
                        item.week +
                        " (" +
                        item.start_date +
                        " - " +
                        item.end_date +
                        ")",
                      value: item.week,
                    };
                  }
                },
                options,
                selected
              );
              this.weekList.options = options;
              this.weekList.selected = selected;
            } else {
              this.$vs.loading.close();
            }
          });
      }
    },
    setSalesChannelId(val) {
      console.log(val);
      this.salesChannelId = val;
    },
    setCustomerCategoryId(val) {
      this.customerCategoryId = val;
    },
    setSalesPersonalId(val) {
      console.log(val);
      this.salesPersonalId = val;
    },
    setManagerPersonalId(val) {
      this.managerPersonalId = val;
    },
    setDirectorPersonalId(val) {
      this.directorPersonalId = val;
    },
    setTerritoryId(val) {
      this.territoryId = val;
    },
    setTerritoryAreaId(val) {
      this.territoryAreaId = val;
    },
  },
  mounted() {
    this.getDataWeeklist();
  },
  watch: {
    selectedYear: function (v) {
      this.year = v.getFullYear();
      this.getDataWeeklist();
    },
    "weekList.selected": function (val) {
      if (val) {
        this.week = val.value;
      } else {
        this.week = null;
      }
    },
  },
};
</script>