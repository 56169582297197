<template>
  <div>
    <div class="vx-row">
      <card-total-s-o></card-total-s-o>
      <card-total-incoming-s-o></card-total-incoming-s-o>
      <card-total-s-o-need-approve></card-total-s-o-need-approve>
      <card-total-d-p-need-approve></card-total-d-p-need-approve>
      <card-total-kitting-need-approve></card-total-kitting-need-approve>
    </div>
  </div>
</template>
<script>
import CardTotalSO from "./dashboard-component/CardTotalSO";
import CardTotalIncomingSO from "./dashboard-component/CardTotalIncomingSO";
import CardTotalSONeedApprove from "./dashboard-component/CardTotalSONeedApprove";
import CardTotalDPNeedApprove from "./dashboard-component/CardTotalDPNeedApprove";
import CardTotalKittingNeedApprove from "./dashboard-component/CardTotalKittingNeedApprove";
export default {
  components: {
    CardTotalSO,
    CardTotalIncomingSO,
    CardTotalSONeedApprove,
    CardTotalDPNeedApprove,
    CardTotalKittingNeedApprove
  }
};
</script>