<template>
  <div>
    <vue-apex-charts type="line" ref="chart" height="253" :options="options" :series="series" />
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
export default {
  components: {
    VueApexCharts
  },
  props: {
    type: {
      type: String,
      require: true
    },
    year: {
      type: Number,
      require: true
    },
    month: {
      type: Number
    },
    week: {
      type: Number
    },
    salesChannelId: {
      type: Number
    },
    customerCategoryId: {
      type: Number
    },
    territoryId: {
      type: Number
    },
    territoryAreaId: {
      type: Number
    },
    salesPersonalId: {
      type: Number
    },
    managerPersonalId: {
      type: Number
    },
    directorPersonalId: {
      type: Number
    }
  },
  data() {
    return {
      baseUrl: "/api/v1/dashboard/grafik-value-so",
      series: [],
      options: {
        chart: {
          toolbar: { show: false },
          dropShadow: {
            enabled: true,
            top: 5,
            left: 0,
            blur: 4,
            opacity: 0.1
          }
        },
        stroke: {
          curve: "smooth",
          dashArray: [0, 8],
          width: [4, 2]
        },
        grid: {
          borderColor: "#e7e7e7"
        },
        legend: {
          show: false
        },
        colors: ["#F97794", "#b8c2cc"],
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            inverseColors: false,
            gradientToColors: ["#7367F0", "#b8c2cc"],
            shadeIntensity: 1,
            type: "horizontal",
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100]
          }
        },
        markers: {
          size: 0,
          hover: {
            size: 5
          }
        },
        xaxis: {
          labels: {
            style: {
              cssClass: "text-black fill-current"
            }
          },
          axisTicks: {
            show: false
          },
          categories: [],
          axisBorder: {
            show: false
          }
        },
        yaxis: {
          tickAmount: 5,
          labels: {
            style: {
              cssClass: "text-black fill-current"
            },
            formatter: this.currencyFormat
          }
        },
        tooltip: {
          x: { show: false }
        }
      }
    };
  },
  methods: {
    getData() {
      this.$http
        .get(this.baseUrl, {
          params: {
            type: this.type,
            year: this.year,
            month: this.month,
            week: this.week,
            sales_channel_id: this.salesChannelId,
            customer_category_id: this.customerCategoryId,
            sales_personal_id: this.salesPersonalId,
            manager_personal_id: this.managerPersonalId,
            director_personal_id: this.directorPersonalId,
            territory_id: this.territoryId,
            territory_area_id: this.territoryAreaId
          }
        })
        .then(resp => {
          if (resp.status == "success") {
            this.$refs.chart.updateOptions({
              xaxis: {
                categories: resp.data.categories
              }
            });
            this.$refs.chart.updateSeries(resp.data.series);
          } else {
            this.$vs.loading.close();
          }
        });
    },
    currencyFormat(val) {
      if (val > 999 && val < 1000000) {
        return (val / 1000).toFixed(1) + " k";
      } else if (val > 999999 && val < 1000000000) {
        return (val / 1000000).toFixed(1) + " M";
      } else if (val > 999999999) {
        return (val / 1000000000).toFixed(1) + " B";
      } else {
        return val;
      }
    }
  },
  mounted() {
    this.getData();
  },
  watch: {
    week() {
      this.getData();
    },
    month() {
      this.getData();
    },
    year() {
      this.getData();
    },
    salesChannelId() {
      this.getData();
    },
    customerCategoryId() {
      this.getData();
    },
    salesPersonalId() {
      this.getData();
    },
    managerPersonalId() {
      this.getData();
    },
    directorPersonalId() {
      this.getData();
    },
    territoryId() {
      this.getData();
    },
    territoryAreaId() {
      this.getData();
    }
  }
};
</script>