<template>
  <div v-if="show" class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/5 xl:w-1/5 mb-base">
    <statistics-card-line
      icon="CheckSquareIcon"
      :statistic="statistic"
      statisticTitle="Delivery Plan Need Approve"
      :chartData="series"
      type="area"
      color="warning"
    />
  </div>
</template>
<script>
import StatisticsCardLine from "@/components/statistics-cards/StatisticsCardLine.vue";
export default {
  components: {
    StatisticsCardLine
  },
  data() {
    return {
      baseUrl: "/api/v1/dashboard/total-dp-need-approve",
      series: [],
      statistic: 0,
      date: ""
    };
  },
  methods: {
    getData() {
      this.$http
        .get(this.baseUrl, {
          params: {
            date: this.date
          }
        })
        .then(resp => {
          if (resp.status == "success") {
            this.statistic = resp.data.total;
            this.series = resp.data.series;
          } else {
            this.$vs.loading.close();
          }
        });
    }
  },
  computed: {
    show() {
      let show = false;
      if (this.$userLogin.roles) {
        this.$userLogin.roles.filter(function(item) {
          switch (item.Name) {
            case "territory_manager":
              show = true;
              break;
          }
        }, show);
      }
      return show;
    }
  },
  mounted() {
    this.getData();
  }
};
</script>