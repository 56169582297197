<template>
  <div v-if="show" class="vx-col md:w-1/3 lg:w-1/3 xl:w-1/3 mr-5">
    <label class="vs-input--label">Sales</label>
    <v-select v-model="selected" :options="options" placeholder="All" />
  </div>
</template>
<script>
import vSelect from "vue-select";
export default {
  components: {
    "v-select": vSelect
  },
  props: {
    territoryId: {
      type: Number
    },
    parentPersonalId: {
      type: Number
    }
  },
  data() {
    return {
      selected: null,
      options: [],
      mutableTerritoryId: null,
      mutableParentPersonalId: null
    };
  },
  methods: {
    getData() {
      this.$http
        .get("/api/v1/dashboard/sales-list", {
          params: {
            territory_id: this.mutableTerritoryId,
            parent_personal_id: this.mutableParentPersonalId
          }
        })
        .then(resp => {
          if (resp.status == "success") {
            var options = [];
            resp.data.forEach(function(item) {
              options.push({
                label: item.name,
                value: item.id
              });
            }, options);
            this.options = options;
          } else {
            this.$vs.loading.close();
          }
        });
    }
  },
  mounted() {
    this.mutableTerritoryId = this.$userLogin.territory_id;
    this.mutableParentPersonalId = this.$userLogin.id;
    this.getData();
  },
  computed: {
    show() {
      let show = false;
      if (this.$userLogin.roles) {
        this.$userLogin.roles.filter(function(item) {
          switch (item.Name) {
            case "superadmin":
              show = true;
              break;
            case "admin":
              show = true;
              break;
            case "sales_admin":
              show = true;
              break;
            case "territory_manager":
              show = true;
              break;
          }
        }, show);
      }
      return show;
    }
  },
  watch: {
    selected: function(val) {
      if (val) {
        this.$emit("salesPersonalId", val.value);
      } else {
        this.$emit("salesPersonalId", null);
      }
    },
    territoryId(val) {
      this.mutableTerritoryId = val;
      this.getData();
    },
    mutableTerritoryId() {
      this.getData();
    },
    mutableParentPersonalId() {
      this.getData();
    }
  }
};
</script>