<template>
  <div v-if="show" class="vx-col md:w-1/3 lg:w-1/3 xl:w-1/3 mr-5">
    <label class="vs-input--label">Distribution Channel</label>
    <v-select v-model="selected" :options="options" placeholder="All" />
  </div>
</template>
<script>
import vSelect from "vue-select";
export default {
  components: {
    "v-select": vSelect
  },
  data() {
    return {
      selected: null,
      options: []
    };
  },
  methods: {
    getData() {
      this.$http.get("/api/v1/master/customer-category").then(resp => {
        if (resp.status == "success") {
          var options = [];
          resp.data.records.forEach(function(item) {
            options.push({
              label: item.name,
              value: item.id
            });
          }, options);
          this.options = options;
        } else {
          this.$vs.loading.close();
        }
      });
    }
  },
  mounted() {
    this.getData();
  },
  computed: {
    show() {
      let show = false;
      if (this.$userLogin.roles) {
        this.$userLogin.roles.filter(function(item) {
          switch (item.Name) {
            case "superadmin":
              show = true;
              break;
            case "admin":
              show = true;
              break;
            case "fulfillment":
              show = true;
              break;
          }
        }, show);
      }
      return show;
    }
  },
  watch: {
    selected: function(val) {
      if (val) {
        this.$emit("customerCategoryId", val.value);
      } else {
        this.$emit("customerCategoryId", null);
      }
    }
  }
};
</script>