<template>
  <div
    v-if="show"
    class="vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3 mb-base"
    style="border-right: solid 1px #e6e6e6; border-left: solid 1px #e6e6e6"
  >
    <div :class="special.status == 'down' ? 'text-danger' : 'text-success'">
      <feather-icon
        :icon="special.status == 'down' ? 'ArrowDownIcon' : 'ArrowUpIcon'"
        :svgClasses="['stroke-current h-4 w-4 mb-1 mr-1']"
      ></feather-icon>
      {{ special.comparedValue }}%
    </div>
    <span>
      <b>{{ Math.round(special.nominalValue).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}</b>
    </span>
    <div>SPECIAL GOAL COMPLETION</div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    type: {
      type: String,
    },
    year: {
      type: Number,
    },
    month: {
      type: Number,
    },
    week: {
      type: Number,
    },
    salesChannelId: {
      type: Number,
    },
    customerCategoryId: {
      type: Number,
    },
    status: {
      type: String,
    },
  },
  data() {
    return {
      special: {
        nominalValue: 0,
        comparedValue: 0,
        status: "",
      },
    };
  },
  methods: {
    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/dashboard/total-completion", {
          params: {
            type: this.type,
            year: this.year,
            month: this.month,
            week: this.week,
            sales_channel_id: this.salesChannelId,
            customer_category_id: this.customerCategoryId,
            status: this.status,
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            if (resp.data[0] != null) {
              this.special.nominalValue = resp.data[0].goal_completion;
              this.special.comparedValue = resp.data[0].compared_value;
              this.special.status = resp.data[0].status;
            }

            this.$vs.loading.close();
          }
        });
    },
  },
  mounted() {
    this.getData();
  },
  computed: {
    show() {
      let show = true;
      if (this.$userLogin.roles) {
        this.$userLogin.roles.filter(function (item) {
          switch (item.Name) {
            case "fulfillment":
              show = false;
              break;
          }
        }, show);
      }
      return show;
    },
  },
  watch: {
    week() {
      this.getData();
    },
    month() {
      this.getData();
    },
    year() {
      this.getData();
    },
    salesChannelId() {
      this.getData();
    },
    customerCategoryId() {
      this.getData();
    },
  },
};
</script>