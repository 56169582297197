<template>
  <div
    class="vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3 mb-base ml-5 mr-5"
    style="box-shadow: 5px 10px 5px #e6e6e6"
  >
    <p v-if="show" class="mb-1 font-semibold">Total SO Last {{ label }}</p>
    <p v-else class="mb-1 font-semibold">Last {{ label }}</p>
    <p v-if="show" class="text-3xl">
      <sup class="text-base mr-1"></sup>
      {{ lastTotalSO }}
    </p>
    <p v-else class="text-3xl">
      <sup class="text-base mr-1">IDR</sup>
      {{
        Math.round(lastTotalAmount)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
    },
    type: {
      type: String,
      require: true,
    },
    year: {
      type: Number,
      require: true,
    },
    month: {
      type: Number,
    },
    week: {
      type: Number,
    },
    salesChannelId: {
      type: Number,
    },
    customerCategoryId: {
      type: Number,
    },
    territoryId: {
      type: Number,
    },
    territoryAreaId: {
      type: Number,
    },
    salesPersonalId: {
      type: Number,
    },
    managerPersonalId: {
      type: Number,
    },
    directorPersonalId: {
      type: Number,
    },
  },
  data() {
    return {
      totalSO: 0,
      lastTotalSO: 0,
      totalAmount: 0,
      lastTotalAmount: 0,
    };
  },
  methods: {
    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/dashboard/total-so", {
          params: {
            type: this.type,
            year: this.year,
            month: this.month,
            week: this.week,
            sales_channel_id: this.salesChannelId,
            customer_category_id: this.customerCategoryId,
            sales_personal_id: this.salesPersonalId,
            manager_personal_id: this.managerPersonalId,
            director_personal_id: this.directorPersonalId,
            territory_id: this.territoryId,
            territory_area_id: this.territoryAreaId,
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.totalSO = resp.data[0].total_so;
            this.lastTotalSO = resp.data[0].last_total_so;
            this.totalAmount = resp.data[0].total_amount;
            this.lastTotalAmount = resp.data[0].last_total_amount;

            this.$vs.loading.close();
          }
        });
    },
  },
  mounted() {
    this.getData();
  },
  computed: {
    show() {
      let show = false;
      if (this.$userLogin.roles) {
        this.$userLogin.roles.filter(function (item) {
          switch (item.Name) {
            case "fulfillment":
              show = true;
              break;
          }
        }, show);
      }
      return show;
    },
  },
  watch: {
    week() {
      this.getData();
    },
    month() {
      this.getData();
    },
    year() {
      this.getData();
    },
    salesChannelId() {
      this.getData();
    },
    customerCategoryId() {
      this.getData();
    },
    salesPersonalId() {
      this.getData();
    },
    managerPersonalId() {
      this.getData();
    },
    directorPersonalId() {
      this.getData();
    },
    territoryId() {
      this.getData();
    },
    territoryAreaId() {
      this.getData();
    },
  },
};
</script>